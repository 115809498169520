<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"
      />
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"
      />
      <v-container class="container--fluid grid-list-md text-center">
        <h1>Job Services</h1>
      </v-container>
      <v-card elevation="2" outlined shaped tile class="pa-1">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title>
            <v-container grid-list-md text-xs-center>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-btn
                    :block="$vuetify.breakpoint.mdAndDown"
                    class="mt-2"
                    color="success"
                    @click.stop="showJobForm('CREATED')"
                    v-if="hasPermission('ROLE_CREATE_JOB')"
                    >Create Job</v-btn
                  >
                  <job-form
                    ref="form"
                    :selected-item="selectedJob"
                    :is-show="showFormDialog"
                    :form-type="jobFormType"
                    v-on:close-dialog="closeDialog"
                    v-on:save-job="saveJob"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
        </div>
      </v-card>
      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="displayTransactions"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 200],
        }"
        :items-per-page="20"
        :server-items-length="totalTransactions"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr :colspan="headers.length">
              <th v-for="header in headers" :key="header.text">
                <span class="d-flex justify-center">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
              <td>
                <span class="d-flex justify-center"> {{ item.id }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ getJobTypeText(item.jobType) }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.jobName }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.schedule }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ getTimeTypeText(item.timeType) }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.isRunning }} </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        @click.stop="viewDetails(item)"
                      >
                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Details</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        @click.stop="showJobForm('UPDATE', item)"
                        v-if="hasPermission('ROLE_UPDATE_JOB')"
                      >
                        <v-icon dark medium>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        @click.stop="setSelectedJob(item, 'RUN')"
                        v-if="item.isRunning === false" 
                      >
                        <v-icon dark medium>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    <span>Run</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        @click.stop="setSelectedJob(item, 'CANCEL')"
                        v-if="item.isRunning === true" 
                      >
                        <v-icon dark medium>mdi-stop</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancel</span>
                  </v-tooltip>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Found no results.
          </v-alert>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogRun" max-width="500" :retain-focus="false">
        <v-card>
          <v-card-title class="headline">
            Run selected job?
          </v-card-title>
          <v-card-text>
            Run {{ selectedJob.id }}: {{ selectedJob.jobName }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click.stop="
                setJobProcess({
                  id: selectedJob.id,
                  jobName: selectedJob.jobName,
                  runningStatus: 'run',
                  jobs: [selectedJob.jobName]
                })
              "
            >
              Confirm
            </v-btn>
            <v-btn color="gray darken-1" text @click="dialogRun = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCancel" max-width="500" :retain-focus="false">
        <v-card>
          <v-card-title class="headline">
            Cancel selected job?
          </v-card-title>
          <v-card-text>
            Cancel {{ selectedJob.id }}: {{ selectedJob.jobName }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click.stop="
                setJobProcess({
                  id: selectedJob.id,
                  jobName: selectedJob.jobName,
                  runningStatus: 'cancel',
                  jobs: [selectedJob.jobName]
                })
              "
            >
              Confirm
            </v-btn>
            <v-btn color="gray darken-1" text @click="dialogCancel = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js"
import Loading from "@/views/components/Loading.vue"
import JobForm from "@/views/components/JobForm.vue"
import DetailsForm from "@/views/components/DetailsForm.vue"
import { mapGetters } from "vuex"

import {
  JOB_TYPE,
  TIME_TYPE,
  MONTHLY,
  WEEKLY,
  CALCULATION
} from "@/constants/job.js"

export default {
  name: "jobServices",
  components: {
    Loading,
    JobForm,
    DetailsForm,
  },
  data() {
    return {
      dialogRun: false,
      dialogCancel: false,
      search: "",
      headers: [],
      jobTypeList: JOB_TYPE,
      displayTransactions: [],
      currentTransactions: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      jobFormType: "CREATED",
      selectedJob: {},
      page: 1,
      totalTransactions: 0,
      numberOfPages: 0,
      options: {},
      details: {
        title: "",
        item: {},
      },
      query: {},
      isShowDetails: false,
      showFormDialog: false,
      isHtml: false,
    }
  },
  watch: {
    options: {
      handler() {
        console.log("options")
        this.queryData()
      },
      deep: true,
    },
    params: {
      handler: function handler() {
        this.filter()
      },
      deep: true,
    },
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      }
    },
  },
  async mounted() {
    this.setHeaders()
  },
  methods: {
    getJobTypeText(id) {
      const jobType = JOB_TYPE.find((x) => x.value === id)
      return jobType ? jobType.text : id
    },
    getTimeTypeText(id) {
      const timeType = TIME_TYPE.find((x) => x.value === id)
      return timeType ? timeType.text : id
    },
    hasPermission(authority) {
      if (this.authorities.some((x) => x === authority)) {
        return true
      }
      return false
    },
    async queryData(isSearch = false) {
      this.loading = true
      if (isSearch) {
        this.options.page = 1
      }
      const { page, itemsPerPage } = this.options
      let pageNumber = page - 1
      try {
        const limit = itemsPerPage
        const transactionListResponse = await API.getAllJobList(
          pageNumber,
          limit,
          this.query
        )

        if (!transactionListResponse || transactionListResponse.error) {
          //error getting data
          console.log(`${transactionListResponse.error}`)
        } else {
          this.displayTransactions = transactionListResponse
          this.totalTransactions =
            page > 1
              ? pageNumber * itemsPerPage + this.displayTransactions.length
              : this.displayTransactions.length
          this.numberOfPages = this.totalTransactions / itemsPerPage
          const hasMoreResponse = await API.getAllJobList(
            this.totalTransactions + 1,
            1,
            this.query
          )
          if (!hasMoreResponse || hasMoreResponse.error) {
            //error getting data
            console.log(`${hasMoreResponse.error}`)
          } else {
            var hasMoreList = hasMoreResponse
            if (hasMoreList.length > 0) {
              this.totalTransactions = this.totalTransactions + 1
              this.numberOfPages = this.numberOfPages + 1
            }
          }
        }
        this.currentTransactions = this.displayTransactions
        this.filter()
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    filter() {
      let search = this.search.trim().toLowerCase()
      if (search) {
        this.displayTransactions = this.currentTransactions.filter((item) => {
          return Object.values(item).join(",").toLowerCase().includes(search)
        })
      } else {
        this.displayTransactions = this.currentTransactions
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "Job ID",
          value: "id",
        },
        {
          text: "Job Type",
          value: "jobType",
        },
        {
          text: "Job Name",
          value: "jobName",
        },
        {
          text: "Schedule",
          value: "schedule",
        },
        {
          text: "Time Type",
          value: "timeType",
        },
        {
          text: "Is Running",
          value: "isRunning",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ]
      this.headers = headers
    },
    showJobForm(what, item = {}) {
      this.jobFormType = what
      this.selectedJob = item
      this.showFormDialog = true
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = ""
        this.details.item = {}
        this.isShowDetails = false
      } else if (type === "CREATED" || type === "UPDATE") {
        this.showFormDialog = false
      } else if (type === "loading") {
        this.loadingDialog = false
        this.$router.go(0)
      } else if (type === "loading-error") {
        this.loadingDialog = false
      }
    },
    async saveJob(value) {
      this.loadingDialog = true
      this.isHtml = true
      this.loadingFinished = false
      if (value) {
        if (value.formType === "CREATED") {
          this.loadingMessage = `Creating new job <strong><i>${value.jobName}</i></strong>`
          setTimeout(async () => {
            const createResponse = await API.createJobs(value)
            this.loadingFinished = true
            if (!createResponse || createResponse.error) {
              this.isHtml = false
              this.loadingMessage = createResponse.error.message
            } else {
              this.loadingMessage = `Successfully created job <strong><i>${value.jobName}</i></strong>`
              this.showFormDialog = false
              this.$refs.form.resetForm()
            }
          }, 2000)
        } else if (value.formType === "UPDATE") {
          this.loadingMessage = `Updating job <strong><i>${value.jobName}</i></strong>`
          setTimeout(async () => {
            const updateResponse = await API.updateJobs(value)
            this.loadingFinished = true
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false
              this.loadingMessage = updateResponse.error.message
            } else {
              this.loadingMessage = `Successfully updated job <strong><i>${value.jobName}</i></strong>`
              this.showFormDialog = false
              this.$refs.form.resetForm()
            }
          }, 2000)
        } else {
          this.loadingMessage = "Invalid command"
          this.loadingFinished = true
        }
      } else {
        this.loadingMessage = "Error processing data."
        this.loadingFinished = true
      }
    },
    async setJobProcess(value) {
      this.loadingDialog = true
      this.isHtml = true
      this.loadingFinished = false
      if (value) {
        this.loadingMessage = `Processing ${value.id}: ${value.jobName} running status`
        setTimeout(async () => {
          const createResponse = await API.setJobRunningStatus(value)
          this.loadingFinished = true
          this.dialogRun = false
          this.dialogCancel = false
          if (!createResponse || createResponse.error) {
            this.isHtml = false
            this.loadingMessage = createResponse.error.message
          } else {
            this.loadingMessage = `Successfully set ${value.id}: ${value.jobName} running status`
            this.showFormDialog = false
          }
        }, 2000)
      } else {
        this.loadingMessage = `Error changing running status for ${value.id}: ${value.jobName}`
        this.loadingFinished = true
        this.dialogRun = false
        this.dialogCancel = false
      }
    },
    viewDetails(details) {
      this.details.title = details.externalId
      this.details.item = Object.assign({}, details)

      const calculation = CALCULATION.find((x) => x.value === this.details.item.calculation)
      const timeType = TIME_TYPE.find((x) => x.value === this.details.item.timeType)
      const jobType = JOB_TYPE.find((x) => x.value === this.details.item.jobType)

      this.details.item.calculation = calculation ? calculation.text : this.details.item.calculation
      this.details.item.timeType = timeType ? timeType.text : this.details.item.timeType
      this.details.item.jobType = jobType ? jobType.text : this.details.item.jobType

      if (this.details.item.timeSubtype) {
        const month = this.details.item.timeSubtype.month ? MONTHLY.find((x) => x.value === this.details.item.timeSubtype.month) : ''
        const day = this.details.item.timeSubtype.day ? WEEKLY.find((x) => x.value === this.details.item.timeSubtype.day) : ''
        this.details.item.timeSubtype = `${month.text ?? ''} ${day.text ?? ''} ${this.details.item.timeSubtype.date ?? ''}` 
      }
      this.isShowDetails = true
    },
    setSelectedJob(item, dialogType) {
      if (dialogType === "RUN") {
        this.dialogRun = true
      } else {
        this.dialogCancel = true
      }
      this.selectedJob = item
    },
  },
}
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
