var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"px-4"},[_c('loading',{attrs:{"is-show":_vm.loadingDialog,"is-done":_vm.loadingFinished,"is-html":_vm.isHtml,"message":_vm.loadingMessage},on:{"close-dialog":_vm.closeDialog}}),_c('details-form',{attrs:{"is-show":_vm.isShowDetails,"details":_vm.details},on:{"close-dialog":_vm.closeDialog}}),_c('v-container',{staticClass:"container--fluid grid-list-md text-center"},[_c('h1',[_vm._v("Job Services")])]),_c('v-card',{staticClass:"pa-1",attrs:{"elevation":"2","outlined":"","shaped":"","tile":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('v-card-title',[_c('v-container',{attrs:{"grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[(_vm.hasPermission('ROLE_CREATE_JOB'))?_c('v-btn',{staticClass:"mt-2",attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.showJobForm('CREATED')}}},[_vm._v("Create Job")]):_vm._e(),_c('job-form',{ref:"form",attrs:{"selected-item":_vm.selectedJob,"is-show":_vm.showFormDialog,"form-type":_vm.jobFormType},on:{"close-dialog":_vm.closeDialog,"save-job":_vm.saveJob}})],1)],1)],1)],1)],1)]),_c('v-data-table',{attrs:{"hide-default-header":true,"headers":_vm.headers,"items":_vm.displayTransactions,"search":_vm.search,"loading":_vm.loading,"options":_vm.options,"footer-props":{
        'items-per-page-options': [10, 20, 50, 200],
      },"items-per-page":20,"server-items-length":_vm.totalTransactions},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var headers = ref.props.headers;
return [_c('thead',[_c('tr',{attrs:{"colspan":headers.length}},_vm._l((headers),function(header){return _c('th',{key:header.text},[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(header.text))])])}),0)])]}},{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,attrs:{"colspan":_vm.headers.length}},[_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.getJobTypeText(item.jobType))+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.jobName)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.schedule)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.getTimeTypeText(item.timeType))+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.isRunning)+" ")])]),_c('td',[_c('span',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.viewDetails(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-magnify-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(_vm.hasPermission('ROLE_UPDATE_JOB'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.showJobForm('UPDATE', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.isRunning === false)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedJob(item, 'RUN')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-play")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Run")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [(item.isRunning === true)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.setSelectedJob(item, 'CANCEL')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","medium":""}},[_vm._v("mdi-stop")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Cancel")])])],1)])])}),0)]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Found no results. ")])]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"500","retain-focus":false},model:{value:(_vm.dialogRun),callback:function ($$v) {_vm.dialogRun=$$v},expression:"dialogRun"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Run selected job? ")]),_c('v-card-text',[_vm._v(" Run "+_vm._s(_vm.selectedJob.id)+": "+_vm._s(_vm.selectedJob.jobName)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.setJobProcess({
                id: _vm.selectedJob.id,
                jobName: _vm.selectedJob.jobName,
                runningStatus: 'run',
                jobs: [_vm.selectedJob.jobName]
              })}}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"gray darken-1","text":""},on:{"click":function($event){_vm.dialogRun = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500","retain-focus":false},model:{value:(_vm.dialogCancel),callback:function ($$v) {_vm.dialogCancel=$$v},expression:"dialogCancel"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Cancel selected job? ")]),_c('v-card-text',[_vm._v(" Cancel "+_vm._s(_vm.selectedJob.id)+": "+_vm._s(_vm.selectedJob.jobName)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.setJobProcess({
                id: _vm.selectedJob.id,
                jobName: _vm.selectedJob.jobName,
                runningStatus: 'cancel',
                jobs: [_vm.selectedJob.jobName]
              })}}},[_vm._v(" Confirm ")]),_c('v-btn',{attrs:{"color":"gray darken-1","text":""},on:{"click":function($event){_vm.dialogCancel = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }