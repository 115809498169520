"use strict"

const _ = require('lodash');

export const JOB_TYPE = [
  { text: "CREDIT", value: 1 }
]

export const TIME_TYPE = [
  { text: "DAILY", value: 1 },
  { text: "WEEKLY", value: 2 },
  { text: "MONTHLY", value: 3 },
  { text: "ANNUALLY", value: 4 },
]

export const MONTHLY = [
  { text: "January", value: "JAN" },
  { text: "February", value: "FEB" },
  { text: "March", value: "MAR" },
  { text: "April", value: "APR" },
  { text: "May", value: "MAY" },
  { text: "June", value: "JUN" },
  { text: "July", value: "JUL" },
  { text: "August", value: "AUG" },
  { text: "September", value: "SEP" },
  { text: "October", value: "OCT" },
  { text: "November", value: "NOV" },
  { text: "December", value: "DEC" },
]

export const WEEKLY = [
  { text: "Sunday", value: "SUN" },
  { text: "Monday", value: "MON" },
  { text: "Tuesday", value: "TUE" },
  { text: "Wednesday", value: "WED" },
  { text: "Thursday", value: "THU" },
  { text: "Friday", value: "FRI" },
  { text: "Saturday", value: "SAT" },
]

export const DATE = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7", value: 7 },
  { text: "8", value: 8 },
  { text: "9", value: 9 },
  { text: "10", value: 10 },
  { text: "11", value: 11 },
  { text: "12", value: 12 },
  { text: "13", value: 13 },
  { text: "14", value: 14 },
  { text: "15", value: 15 },
  { text: "16", value: 16 },
  { text: "17", value: 17 },
  { text: "18", value: 18 },
  { text: "19", value: 19 },
  { text: "20", value: 20 },
  { text: "21", value: 21 },
  { text: "22", value: 22 },
  { text: "23", value: 23 },
  { text: "24", value: 24 },
  { text: "25", value: 25 },
  { text: "26", value: 26 },
  { text: "27", value: 27 },
  { text: "28", value: 28 },
  { text: "29", value: 29 },
  { text: "30", value: 30 },
  { text: "31", value: 31 },
]

export const CALCULATION = [
  { text: "FLAT", value: 1 },
  { text: "PERCENTAGE", value: 2 }
]


