<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'UPDATE'">Update Job</span>
          <span class="headline" v-else>Create New Job</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="form.jobType"
                    :items="jobTypeItems"
                    item-text="text"
                    item-value="value"
                    label="Job Type*"
                    data-cy="job-type"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Job Name*"
                    v-model="form.jobName"
                    data-cy="job-name"
                    required
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="form.timeType"
                    :items="timeTypeItems"
                    item-text="text"
                    item-value="value"
                    label="Time Type*"
                    data-cy="time-type"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-if="form.timeType === 2"
                    v-model="form.timeSubtype.day"
                    :items="weeklyItems"
                    item-text="text"
                    item-value="value"
                    label="Day*"
                    data-cy="day"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-if="form.timeType === 3 || form.timeType === 4"
                    v-model="form.timeSubtype.date"
                    :items="dateItems"
                    item-text="text"
                    item-value="text"
                    label="Date*"
                    data-cy="date"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-if="form.timeType === 4"
                    v-model="form.timeSubtype.month"
                    :items="monthlyItems"
                    item-text="text"
                    item-value="value"
                    label="Month*"
                    data-cy="month"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-time-picker
                    v-model="form.schedule"
                    label="Schedule*"
                    format="24hr"
                    landscape
                  ></v-time-picker>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Schedule* (Use time picker)"
                    v-model="form.schedule"
                    required
                    :rules="[rules.requiredField]"
                    data-cy="schedule"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="form.calculation"
                    :items="calculationItems"
                    item-text="text"
                    item-value="value"
                    label="Calculation*"
                    data-cy="calculation"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Amount*"
                    v-model="form.amount"
                    required
                    :rules="[rules.requiredField]"
                    type="number"
                    data-cy="amount"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
          <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import {
  JOB_TYPE,
  TIME_TYPE,
  MONTHLY,
  WEEKLY,
  DATE,
  CALCULATION
} from "@/constants/job.js";

export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
    departments: Array,
  },
  computed: {
    populatedForm() {
      return {
        id: this.form.id,
        jobType: this.form.jobType,
        jobName: this.form.jobName,
        timeType: this.form.timeType,
        timeSubtype: this.form.timeSubtype,
        schedule: this.form.schedule,
        calculation: this.form.calculation,
        amount: this.form.amount,
        formType: this.formType,
      };
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    deep: true,
  },
  data() {
    return {
      jobTypeItems: JOB_TYPE,
      timeTypeItems: TIME_TYPE,
      monthlyItems: MONTHLY,
      weeklyItems: WEEKLY,
      dateItems: DATE,
      calculationItems: CALCULATION,
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,
      form: {},
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) =>
          (!!v && v.length > 0) || "Required at least one selection",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
      },
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        this.$emit("save-job", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    initializeForm() {
      if (this.formType === "CREATED") {
        this.form = {
          jobType: undefined,
          jobName: undefined,
          timeType: undefined,
          timeSubtype: {
            day: undefined,
            date: undefined,
            month: undefined
          },
          schedule: undefined,
          calculation: undefined,
          amount: undefined
        }
      } else if (this.formType === "UPDATE") {
        this.form = {
          id: this.selectedItem.id,
          jobType: this.selectedItem.jobType,
          jobName: this.selectedItem.jobName,
          timeType: this.selectedItem.timeType,
          timeSubtype: {
            day: this.selectedItem.timeSubtype ? this.selectedItem.timeSubtype.day ?? '' : '',
            date: this.selectedItem.timeSubtype ? this.selectedItem.timeSubtype.date ?? '' : '',
            month: this.selectedItem.timeSubtype ? this.selectedItem.timeSubtype.month ?? '' : ''
          },
          schedule: this.selectedItem.schedule,
          calculation: this.selectedItem.calculation,
          amount: this.selectedItem.amount
        }
      }
    },
  },
}
</script>
